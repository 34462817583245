<template>
    <div class="cmp-searchbar">
      <div class="form-field">
        <!-- BESPOKE BYD -->
        <i class="fa fa-times-circle" v-if="Object.keys(selectedProduct).length" aria-hidden="true" @click="emptySelectedProduct"></i>
        <!-- END BESPOKE BYD -->
        <input type="text"
          :placeholder="placeholder"
          @keyup="searchString()"
          @keydown.enter="updateByEnter()"
          v-model="textField"/>
      </div>
        <div class="autocomplete-data" v-if="productsFound">
            <div v-for="(option, index) in autocompleteData" :key="index" class="auto-complete-option">
                <div v-on:click="updateFromList(option.key)">
                    <div class="option-key">
                        {{option.key}}
                    </div>
                    <div>
                        <h2 class="option-name">{{ option.value }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="autocomplete-data no-products-found" v-else-if="!productsFound && autocompleteData.length">
            <span v-translation="{ code: 'label_no_products_found', type: 'label' }"></span>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  props: {
    placeholder: { type: String, required: false }
  },
  data () {
    return {
      textField: ''
    }
  },
  computed: {
    ...mapState('orderEntry', ['productsFound', 'autocompleteData', 'searchText', 'selectedProduct'])
  },
  methods: {
    ...mapActions('orderEntry', ['getAutocompleteData', 'updateSearchText', 'getProductInformation', 'clearSelectedProduct']),
    searchString () {
      this.updateSearchText(this.textField);
      console.log(this.textField);
      this.getAutocompleteData();
    },
    updateByEnter () {
      if (this.productsFound) {
        this.getProductInformation();
        this.textField = '';
      }
    },
    updateFromList (id) {
      this.updateSearchText(id);
      this.getProductInformation();
    },
    emptySelectedProduct () {
      this.textField = '';
      this.clearSelectedProduct();
    }
  }
}
</script>

<style>

</style>
