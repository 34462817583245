<template>
  <div class="cmp-favorites-control">

    <a v-if="(!isFavoriteComputed && !touched) || (touched && !isFavorite)"
      @click="addToFavorites()"
      class="favorites-icon add-to">
      <i class="fa fa-heart-o" aria-hidden="true"></i>
    </a>

    <a v-else
      @click="removeFromFavorites()"
      class="favorites-icon remove">
      <i class="fa fa-heart" aria-hidden="true"></i>
    </a>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { Product } from 'src/models/product.model';
export default {
  props: {
    product: { type: Product, required: true }
  },
  data () {
    return {
      touched: false,
      isFavorite: false
    };
  },
  computed: {
    ...mapGetters(['addProductToListEndpoint', 'removeProductFromListEndpoint', 'customerId', 'clientCode']),
    isFavoriteComputed () {
      return this.product.includedInCustomerLists.includes('favorites');
    }
  },
  methods: {
    addToFavorites () {
      const endpoint = `${this.addProductToListEndpoint}?productCode=${this.product.id}&debtorId=${this.customerId}&client=${this.clientCode}&listId=favorites`;
      axios.post(endpoint).then(res => {
        this.touched = !this.touched;
        this.isFavorite = true;
      });
    },
    removeFromFavorites () {
      const endpoint = `${this.removeProductFromListEndpoint}?productCode=${this.product.id}&debtorId=${this.customerId}&client=${this.clientCode}&listId=favorites`;
      axios.post(endpoint).then(res => {
        this.touched = !this.touched;
        this.isFavorite = false;
      });
    }
  },
  created () {
    this.isFavorite = this.product.includedInCustomerLists.includes('favorites');
  }
}
</script>

<style>

</style>
