// BESPOKE
// Import calculatePrice
import { formatPrice, calculatePrice } from 'src/utilities/helpers';
// END BESPOKE

export class Product {
  constructor (product) {
    this.id = product.id;
    this.shortDescription = product.shortDescription;
    this.subTitle = product.subTitle;
    this.longDescription = product.longDescription;
    this.properties = product.properties;
    this.targetUrl = product.targetUrl;
    this.imageUrl = product.imageUrl;
    this.images = product.images;
    this.brand = product.brand;
    this.variantKey = product.variantKey;
    this.units = product.units;
    this.saleUnit = product.saleUnit;
    this.discountGroup = product.discountGroup;
    this.prices = [];
    this.units = this.setUnits(product.units);
    this.stock = null;
    this.variantKey = product.variantKey;
    this.productVariants = [];
    this.customStrings = product.customStrings;
    this.customDecimals = product.customDecimals;
    this.customBooleans = product.customBooleans;
    this.customDateTimes = product.customDateTimes;
    this.includedInCustomerLists = [];
    this.imageAltText = product.id;
    this.imageTitleText = product.id;
  }

  setStock (stock) {
    this.stock = stock;
  }

  setSeoInformation (seoInfo) {
    this.imageAltText = seoInfo.ImageAltText;
    this.imageTitleText = seoInfo.ImageTitleText;
  }

  setUnits (units) {
    if (units) {
      return units.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1)
    } else return null;
  }

  setProductVariants (productVariants) {
    this.productVariants = productVariants;
  }

  setCustomerLists (customerList) {
    this.includedInCustomerLists = customerList;
  }

  setPrices (prices) {
    if (prices !== undefined) {
      // BESPOKE BYD
      // Use bespoked helper functions to format prices 
      // according to the exchange rate and for
      var formattedPrices = [];
      prices.forEach(priceObj => {
        formattedPrices.push({
          rawPrice: calculatePrice(priceObj.price),
          rawBasePrice: calculatePrice(priceObj.basePrice),
          price: formatPrice(priceObj.price),
          basePrice: formatPrice(priceObj.basePrice),
          rawGrossPrice: calculatePrice(this.customDecimals.PRICE),
          grossPrice: formatPrice(this.customDecimals.PRICE),
          quantity: priceObj.quantity === 0 ? 1 : priceObj.quantity
        });
      });
    }
    this.prices = formattedPrices;
    // END BESPOKE BYD
  }
}
