<template>
    <div class="cmp-elastic-order-entry rows utlz-reset">

        <h1 v-translation="{ type:'title', code: 'title_order_entry' }" class="header-25"></h1>

        <utlz-searchbar id="searchbar" :placeholder="placeholder" />

        <div class="orderline-header flex-row-wrap">
          <div class="column-prod-code">            
            <span v-translation="{ type: 'label', code: 'label_header_prod_code' }"></span>
          </div>
          <div class="column-prod-desc">            
            <span v-translation="{ type: 'label', code: 'label_header_prod_title' }"></span>
          </div>
          <div v-if="showPrices" class="column-prod-price">
            <span v-translation="{ type: 'label', code: 'label_header_prod_price' }"></span>
          </div>
          <div class="column-prod-stock">
            <span v-translation="{ type: 'label', code: 'label_header_prod_stock' }"></span>
          </div>
          <div class="column-order-product">
          </div>
          <div class="column-prod-favorites">
          </div>

      </div>

        <!-- BESPOKE BYD-129: Added :orderLineLayout="true" -->
        <transition-group v-if="selectedProduct !== null" name="fade" tag="div" class="list-items-wrapper utlz-row" :class="{ 'warning alert-warning': alternativeProducts.length || replacementProducts.length}">
          <utlz-product-list-item
            :key="selectedProduct.id"
            :product="selectedProduct"
            :blockSize="3"
            :orderLineLayout="true"
            class="order-entry-item">
          </utlz-product-list-item>
        </transition-group>
        <!-- END BESPOKE BYD-129 -->

        <!-- BESPOKE BYD-129: Added alternatives and replacements -->        
        <div class="loading-alternatives flex-row-nowrap just-center" v-if="loadingAlternatives">
          <span class="loader"></span>
          <p v-translation="{ type: 'label', code: 'label_loading_alternatives' }"></p>
        </div>

        <div v-if="replacementProducts.length" class="alternatives-warning flex-row-nowrap just-center alert alert-warning">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <h2 v-translation="{ code: 'title_select_replacement', type: 'title' }"></h2>
        </div>

        <div class="replacement-products">
          <utlz-product-list-item v-if="replacementProducts.length"
            v-for="replacement in replacementProducts"
            :key="replacement.id"
            :product="replacement"
            :blockSize="3"
            :orderLineLayout="true"
            class="order-entry-item">
          </utlz-product-list-item>
        </div>

        <div v-if="alternativeProducts.length" class="alternatives-warning flex-row-nowrap just-center alert alert-warning">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <h2 v-translation="{ code: 'title_select_alternatives', type: 'title' }"></h2>
        </div>

        <div class="alternative-products">
          <utlz-product-list-item v-if="alternativeProducts.length"
            v-for="alternative in alternativeProducts"
            :key="alternative.id"
            :product="alternative"
            :blockSize="3"
            :orderLineLayout="true"
            class="order-entry-item">
          </utlz-product-list-item>
        </div>
        <!-- END BESPOKE BYD-129 -->
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Searchbar from 'src/components/webshop/search-bar/Searchbar.vue';
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
export default {
  data () {
    return {
      placeholder: window.vue.translations.label.label_placeholder_order_entry
    }
  },
  components: {
    'utlz-searchbar': Searchbar,
    'utlz-product-list-item': ProductListItem
  },
  computed: {
    // BESPOKE BYD-129
    // Added loadingAlternatives
    ...mapState('orderEntry', ['selectedProduct', 'loadingAlternatives']),
    // BESPOKE BYD-129: Added mapgetters
    ...mapGetters(['showPrices']),
    ...mapGetters('orderEntry', ['alternativeProducts', 'replacementProducts'])
    // END BESPOKE BYD-129
  }
}
</script>

<style>

</style>
