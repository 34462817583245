<template>
  <div class="cmp-product-reservation cmp-order-product" :class="{ 'using-reference': useReferenceBool }" v-if="moduleOn">
    <div class="cta-wrapper">
      <a @click="toggleReservation()" v-if="!panelOpen" class="reserve-btn" v-translation="{ type: 'button', code: 'button_reservation_prompt'}"></a>
      <a @click="toggleReservation()" v-if="panelOpen" class="reserve-btn cancel" v-translation="{ type: 'button', code: 'button_cancel_reservation' }"></a>
    </div>
    <transition name="fade">
      <div class="reservation-panel" v-if="panelOpen">
        <div class="title-wrapper flex-row-nowrap align-baseline">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <div v-translation="{ type: 'text', code: 'text_reservation_title' }">
        </div>
        </div>
        <div class="actions flex-row-nowrap">
          <input class="reference-field" v-if="useReferenceBool" type="text" :placeholder="phReference" v-model="reservationReference" />
          <utlz-quantity-buttons
            :initialQuantity="1"
            @quantityChanged="quantityChanged($event)"
          ></utlz-quantity-buttons>
          <a class="button theme-primary" @click="reserveProduct()" v-translation="{ type: 'button', code: 'button_reservation' }"></a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
export default {
  props: ['product', 'reference', 'useReference'],    
  components: {
    'utlz-quantity-buttons': QuantityButtons
    },
  data () {
    return {
      panelOpen: false,
      phReference: window.vue.translations.label.label_placeholder_reserve,
      reservationReference: this.reference ? this.reference : '',
      reservationQuantity: 1,
      reservationEndpoint: '/Bespoke/Modules/CreateStockReservation.aspx/StockReservation',
      useReferenceBool: this.useReference ? true : false
      
    };
  },
  computed: {
    ...mapGetters(['bespokeUserInformation', 'language', 'customerId', 'bespokeGlobalWs']),
    moduleOn() {return this.bespokeGlobalWs.reservationModule; }
  },
 methods: {
    toggleReservation() {
      this.panelOpen = !this.panelOpen;
    },
    quantityChanged ({ quantity }) {
      this.reservationQuantity = quantity;
    },
    setReference(reference) {
      this.reservationReference = reference;
    },
    reserveProduct () {
      const payload = {
        prod_code: this.product.id,
        unit_qty: this.reservationQuantity.toString(),
        cu_ln: this.language,
        cu_id: this.bespokeUserInformation.reservationCustomerId,
        deb_id: this.bespokeUserInformation.reservationDebtorId,
        reservation_ref: this.reservationReference
      };

      axios.post(this.reservationEndpoint, payload)
        .then(
          res => {
            const data = res.data.d;
            if (data.message_type === 0) {
              updateOrderMessage(data.message);
              this.toggleReservation();
            }
            else if (data.message_type === 1) {
              window.updateErrorMessage(data.message);
            }
          }
        )
    }
  }
}
</script>

<style>

</style>
