<template>
  <div class="cmp-order-product normalize-headers" :class="parentClassRef">

    <!-- BESPOKE -->
    <template v-if="showIntermediate">
      <utlz-intermediate 
      :product="product"
      :recId="recId"
      :orderQuantity="orderQuantity"
      :backOrderQuantity="backOrderQuantity"
      @toggleIntermediate="toggleIntermediate()"></utlz-intermediate>
    </template>
    <!-- END BESPOKE -->

    <utlz-order-normal
      v-if="orderType === 'normal'"
      @orderNormal="orderProductNormal($event)"
    ></utlz-order-normal>

    <!-- BESPOKE BYD: Hide sale unit -->
    <!-- <utlz-order-saleunit
      v-if="orderType === 'saleUnit'"
      :saleUnit="product.saleUnit"
    ></utlz-order-saleunit> -->
    <!-- END BESPOKE BYD -->

    <utlz-order-units
      v-if="orderType === 'units' && product.stock"
      :units="product.units"
      :stockTotal="product.stock.stockTotal"
      :selectedUnitCode="selectedUnitCode"
      @unitsChanged="unitsChanged($event)"
    ></utlz-order-units>

    <utlz-order-grouped v-if="orderType === 'grouped'" :product="product"></utlz-order-grouped>

    <utlz-order-volume-discount
      v-if="orderType === 'volumeDiscount'"
      :volumeDiscount="product.prices"
      @volumeDiscountChanged="volumeDiscountChanged($event)">
    </utlz-order-volume-discount>

    <input v-if="useOrderComment" type="text" :value="orderCommentField" ref="orderComment" class="order-comment">

    <div class="order-product-wrapper flex-row-nowrap" :class="{ 'disabled': stockLimit && !isValidQuantity }">
      <utlz-quantity-buttons
        :initialQuantity="quantity"
        :quantityFactor="quantityFactor"
        @quantityChanged="quantityChanged($event)">
      </utlz-quantity-buttons>

      <template v-if="!product.customBooleans.NOT_AVAILABLE_PROD">
        <a v-if="useOrderMode" @click="orderProduct" class="button add-to-cart-btn small">
          <i class="uws-icon uws-cart" aria-hidden="true"></i>
        </a>        
      </template>
      <template v-else>
        <a class="button order-lock" title="This product is not orderable from the webshop, please contact customer support">
          <i class="fa fa-lock"></i>
        </a>
      </template>

      <!-- Displays stocklimit exceeded tooltip -->
      <transition name="fade">
        <template v-if="stockLimit && !isValidQuantity">
          <div class="utlz-tooltip stock-limit danger">
            <p v-translation="{ type: 'label', code: 'label_exceeds_stock_limit' }"></p>
          </div>
        </template>
      </transition>

    </div>

  </div>
</template>

<script>
// BESPOKE
import axios from 'axios';
import Intermediate from './../../../intermediate/Intermediate.vue';
// END BESPOKE
import OrderNormal from 'src/components/webshop/order-product/blocks/OrderNormal.vue';
import OrderSaleUnit from 'src/components/webshop/order-product/blocks/OrderSaleUnit.vue';
import OrderUnits from 'src/components/webshop/order-product/blocks/OrderUnits.vue';
import OrderGrouped from 'src/components/webshop/order-product/blocks/OrderGrouped.vue';
import OrderVolumeDiscount from 'src/components/webshop/order-product/blocks/OrderVolumeDiscount.vue';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
import { Product } from 'src/models/product.model';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    'utlz-order-normal': OrderNormal,
    'utlz-order-saleunit': OrderSaleUnit,
    'utlz-order-units': OrderUnits,
    'utlz-order-grouped': OrderGrouped,
    'utlz-order-volume-discount': OrderVolumeDiscount,
    'utlz-quantity-buttons': QuantityButtons,
    'utlz-quantity-buttons': QuantityButtons,
    // BESPOKE BYD
    'utlz-intermediate': Intermediate
    // END BESPOKE BYD
  },
  props: {
    product: { type: Product, required: true },
    initialQuantity: { type: Number, required: false, default: 1 },
    useOrderComment: { type: Boolean, required: false, default: false },
    orderComment: { type: String, required: false },
    useOrderMode: { type: Boolean, default: true },
    parentClassRef: { type: String, default: '', required: false }
  },
  data () {
    return {
      quantity: this.initialQuantity,
      quantityFactor: 1,
      orderType: 'normal',
      selectedUnitCode: '',
      // BESPOKE BYD
      showIntermediate: false,
      orderQuantity: 0,
      backOrderQuantity: 0,
      recId: ""
      // END BESPOKE BYD
    };
  },
  computed: {
    ...mapGetters(['stockLimit', 'showStock']),
    orderCommentField () {
      return this.orderComment;
    },
    isValidQuantity () {
      // BESPOKE BYD
      if (!this.product.customBooleans.STOCK_PRODUCT) {
        return true;
      }
      // END BESPOKE BYD
      if (this.stockLimit) {
        if (this.quantity <= this.product.stock.stockTotal) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  },
  watch: {
    product () {
      this.setOrderType();
      this.setOrderQuantities();
    }
  },
  methods: {
    setOrderType () {
      let type = 'normal';
      if (this.product.units) {
        type = 'units';
      } else if (this.product.saleUnit > 1) {
        type = 'saleUnit';
      }
      this.orderType = type;
    },
    setOrderQuantities () {
      switch (this.orderType) {
        case 'normal':
          this.quantityFactor = 1;
          this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          break;
        case 'saleUnit':
          this.quantityFactor = this.product.saleUnit;
          this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          break;
        case 'volumeDiscount':
          this.quantityFactor = this.product.prices[0].quantity;
          this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          break;
        case 'units':
          this.selectedUnitCode = this.product.units[0].code;
          this.quantityFactor = this.product.units[0].quantity;
          this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          break;
      }
    },
    orderProduct () {
      // BESPOKE BYD
      // Added logic to handle order product with backorders
      this.handleBackOrder();
      // let payload = this.getOrderPayload();
      // this.addToCart({ payload: payload, product: this.product })
      //   .then(res => {
      //     this.quantity = this.quantityFactor;
      //   });
      // END BESPOKE BYD
    },
    getOrderPayload () {
      let quantity = this.quantity;
      if (this.orderType === 'units') {
        quantity = this.quantity / this.quantityFactor;
      } else if (this.orderType === 'volumeDiscount' && this.product.saleUnit > 1) {
        quantity = this.quantity / this.product.saleUnit;
      } else if (this.orderType === 'saleUnit') {
        quantity = this.quantity / this.product.saleUnit;
      }
      return {
        prod_code: "0",
        ord_qty: quantity,
        unit_code: this.selectedUnitCode,
        prod_comment: this.useOrderComment ? this.$refs.orderComment.value : ''
      }
    },
    quantityChanged ({ quantity }) {
      this.quantity = quantity;
    },
    unitsChanged ({ quantity, unitCode }) {
      this.selectedUnitCode = unitCode;
      this.quantity = quantity;
      this.quantityFactor = quantity;
    },
    volumeDiscountChanged (quantity) {
      this.quantity = quantity;
      this.quantityFactor = quantity;
    },
    ...mapActions('shoppingCart', ['addToCart']),
    // BESPOKE BYD
    // Implement backorder logic
    handleBackOrder() {
      
      let qantityPayload = this.getOrderPayload();

      let backOrderPayload = { products: [
        { prod_code: this.product.id, ord_qty: qantityPayload.ord_qty, unit_code: qantityPayload.unit_code }
      ]};

      axios.post("/Bespoke/WebshopAPI/ProductListWebmethods.aspx/CheckForBackorder", backOrderPayload)
        .then(
          res => {
            var response = res.data.d[0];
            
            var orderProductPayload = {
              back_qty: response.back_qty,
              cur_back_qty: response.cur_back_qty,
              cur_ord_qty: response.ord_qty,
              in_basket: response.in_basket,
              ord_qty: response.ord_qty,
              prod_code: response.prod_code,
              prod_comments: response.prod_comments,
              stock: response.stock,
              success: response.success,
              unit_code: response.unit_code
            };

            this.addToCart(orderProductPayload).then(({useIntermediate, recId}) => { 
              if (useIntermediate && !this.product.customBooleans.STOCK_PRODUCT) {
                this.recId = recId,
                this.orderQuantity = orderProductPayload.ord_qty;
                this.backOrderQuantity = orderProductPayload.back_qty;
                this.showIntermediate = true;
              }
            });
          }
        )
    },
    toggleIntermediate() {
      this.showIntermediate = !this.showIntermediate;
    }
    // END BESPOKE BYD
  },
  created () {
    this.setOrderType();
    this.setOrderQuantities();
  }
};
</script>

<style>
</style>
