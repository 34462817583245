<template>
    <div class="utlz-product-block utlz-reset">
        <transition-group name="fade" tag="div" class="list-items-wrapper utlz-row">
            <utlz-product-list-item
              v-for="product in products"
              :key="product.id"
              :product="product"
              :blockSize="config.ItemSize"
              class="product-list-item">
            </utlz-product-list-item>
          </transition-group>
    </div>
</template>
<script>
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
import { Product } from 'src/models/product.model';
import axios from 'axios';

export default {
  computed: {
    config () {
      return this.$root.$data.config;
    },
    productCodes () {
      return this.config.ProductCodes;
    },
    productInformationUrl () {
      const endpoint = window.vue.globalData.endpoints.productInformation;
      const client = this.config.ClientCode;
      const language = this.config.Language;

      return `${endpoint}/${client}?language=${language}`;
    }
  },
  components: {
    'utlz-product-list-item': ProductListItem
  },
  data () {
    return {
      products: []
    }
  },
  methods: {
    getProductInformation () {
      axios.post(this.productInformationUrl, this.productCodes)
        .then(res => {
          const products = res.data.map(product => new Product(product));
          this.products = products;
          if (this.config.ShowStock) {
            this.getProductStock();
          } else {
            products.forEach(product => { product.setStock({ stockTotal: 0 }); })
          }

          if (this.config.ShowPrices) {
            this.getProductPrice()
          }

          if (this.config.ShowFavorites) {
            this.getCustomerLists();
          }
        });
    },
    getProductStock () {
      const url = window.vue.globalData.endpoints.productStockUrl
      this.products.forEach(productInitial => {
        axios.post(url, { 'productCode': productInitial.id })
          .then(res => {
            productInitial.setStock(res.data.d);
            // let index = this.products.findIndex(product => product.id === productWithStock.id);
            // state.products[index] = productWithStock;
          });
      });
    },
    getProductPrice () {
      let priceRequestWrapper = {};
      priceRequestWrapper.CustomerId = window.vue.globalData.userInformation.userLoggedOn ? window.vue.globalData.userInformation.customerId : '';
      priceRequestWrapper.Pricelist = window.vue.globalData.userInformation.customerPriceList;
      priceRequestWrapper.Products = this.products.map(prod => {
        let ret = {};
        ret.ProductId = prod.id;
        ret.ProductGroup = prod.discountGroup;

        ret.ProductUnit = '';
        if (prod.units !== undefined && prod.units !== null) {
          ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
        }
        return ret;
      });

      let endpoint = window.vue.globalData.endpoints.productPriceUrl + 'prices/' + window.vue.globalData.userInformation.clientCode

      axios.post(endpoint, priceRequestWrapper)
        .then(res => {
          res.data.forEach(price => {
            let product = this.products[this.products.findIndex(x => x.id === price.productId)];
            product.setPrices(Object.keys(price.volumes).map(key => price.volumes[key]));
          });
        });
    },
    getCustomerLists () {
      const endpoint = `${window.vue.globalData.endpoints.customerList}?client=${window.vue.globalData.userInformation.clientCode}&debtorId=${window.vue.globalData.userInformation.customerId}`;
      axios.post(endpoint, this.products.map(prod => prod.id))
        .then(res => {
          Object.keys(res.data).forEach(prodCode => {
            let filteredProduct = this.products[this.products.findIndex(product => product.id === prodCode)];
            if (filteredProduct !== undefined) {
              filteredProduct.setCustomerLists(res.data[prodCode]);
            }
          });
        });
    }
  },
  created () {
    this.getProductInformation();
  }
}
</script>
