<template>
  <div class="cmp-product-list-item utlz-col-sm-4" :class="'utlz-col-lg-' + blockSize">
    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper">
      
      <!-- BESPOKE BYD-129: Added a different template to use if orderLineLayout is true, which disables the usual product block layout and creates a simpler row layout  -->
      <template v-if="orderLineLayout">
        <div class="orderline-row flex-row-wrap">
          <div class="column-prod-code">
            <span v-if="mobile" v-translation="{ type: 'label', code: 'label_header_prod_code' }"></span>
            <a :href="product.targetUrl"> 
              <span class="value">{{ product.id }}</span>         
            </a>
          </div>
          <div class="column-prod-desc">
            <a :href="product.targetUrl">
              <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2>       
            </a>
          </div>
          <div class="column-prod-price">
            <span v-if="mobile" v-translation="{ type: 'label', code: 'label_header_prod_price' }"></span>
            <utlz-product-price
              v-if="product.prices.length"
              :prices="product.prices[0]"
              :parentClassRef="'orderline'"
            ></utlz-product-price>
          </div>
          <div class="column-prod-stock">
            <span v-if="mobile" v-translation="{ type: 'label', code: 'label_header_prod_stock' }"></span>
            <utlz-product-stock
              :stock="product.stock"
              :stockTotal="product.stock"
            ></utlz-product-stock>
          </div>
          <div class="column-order-product">
            <transition name="fade">
              <utlz-order-product
                v-if="showOrderProductControl"
                :product="computedProduct"
                :parentClassRef="'ref-product-list-item'"
              >
              </utlz-order-product>
            </transition>

            <transition name="fade">
              <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
                <i class="fa fa-warning"></i>
                <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
              </p>
            </transition>

          </div>
          <div class="column-prod-favorites">
            <utlz-favorites-control :product="product"></utlz-favorites-control>
          </div>
          
          <!-- BESPOKE BYD -->
          <div class="column-order-reservation">
            <utlz-product-reservation 
              :product="product"
              :useReference="true"  
            ></utlz-product-reservation>
          </div>
          <!-- END BESPOKE BYD -->

        </div>
      </template>

      <template v-else>

        <template v-if="product.prices != 'undefined'">
          <span v-if="isSalesAction" v-translation="{ type: 'label', code: 'label_sales_action' }" class="action-banner"></span>
        </template>

      
        <div class="utlz-col-3 utlz-col-sm-12 img-container">
            <utlz-favorites-control v-if="showFavorites" :product="product"></utlz-favorites-control>
            <figure class="img-square prod-img">
              <a :href="product.targetUrl" class="clickthrough" :class="imageRatioClass">
                <img :ref="'productImage'" class="img-fluid" :alt="product.imageAltText" :title="product.imageTitleText" :src="product.imageUrl ? product.imageUrl : '/documents/productimages/not-available-large.jpg'" />
              </a>
            </figure>
        </div>

        <div class="utlz-col-9 utlz-col-sm-12 text-container">
          <a :href="product.targetUrl" class="clickthrough">
            <div class="product-code">
              <span class="value">{{ product.id }}</span>
            </div>
            <h2 class="prod-title header-small header-normal-sm">{{ product.shortDescription }}</h2>
          </a>

          <div class="ph-product-price" v-if="product.prices.length">
            <utlz-product-price
              :prices="product.prices[0]"
              :parentClassRef="'ref-product-list-item'"
            ></utlz-product-price>
          </div>

          <utlz-product-variant
            v-if="product.variantKey && groupProductsList != 0 && showOrderProductControl"
            :product="computedProduct"
            :productVariants="product.productVariants"
            @selectedProduct="selectedProduct($event)"
          ></utlz-product-variant>

          <div class="ph-order-product">
            <transition name="fade">
              <utlz-order-product
                v-if="showOrderProductControl"
                :product="computedProduct"
                :parentClassRef="'ref-product-list-item'"
              >
              </utlz-order-product>
            </transition>

            <transition name="fade">
              <p v-if="showOutOfStockMessage && showStock" class="out-of-stock-msg">
                <i class="fa fa-warning"></i>
                <span v-translation="{ type: 'label', code: 'label_out_of_stock' }"></span>
              </p>
            </transition>
          </div>

          <!-- If product has variants, disable stock and
          use stock in OrderProduct.vue component which is variant specific -->
          <utlz-product-stock
            v-if="!product.variantKey || groupProductsList == 0"
            :stock="product.stock"
            :stockTotal="product.stock"
          ></utlz-product-stock>

        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Product } from 'src/models/product.model';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductVariant from 'src/components/webshop/products/product-variant/ProductVariant.vue';
import FavoritesControl from 'src/components/webshop/favorites/FavoritesControl.vue';

// BESPOKE BYD
import ProductReservation from './../../../../product-reservation/ProductReservation.vue';
// END BESPOKE BYD

export default {
  components: {
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock,
    'utlz-order-product': OrderProduct,
    'utlz-product-variant': ProductVariant,
    'utlz-favorites-control': FavoritesControl,
    // BESPOKE BYD
    'utlz-product-reservation': ProductReservation
    // END BESPOKE BYD
  },
  props: {
    // BESPOKE BYD-129: Add boolean to use orderLineLayout
    orderLineLayout: { type: Boolean, required: false },
    // END BESPOKE
    product: { type: Product, required: true },
    blockSize: { type: Number, required: false, default: 3 }
  },
  data () {
    return {
      isSalesAction: this.product.prices[0],
      activeProductVariantId: null,
      imageRatioClass: ''
    };
  },
  computed: {
    // BESPOKE BYD-129: Added screenWidth
    ...mapGetters(['showStock', 'showPrices', 'showFavorites', 'showOrderProduct', 'stockLimit', 'groupProductsList', 'screenWidth' ]),    
      // BESPOKE BYD-129: Added mobile
    mobile () {
      return  this.screenWidth < 991;
    },
      // END BESPOKE
    showOrderProductControl () {
      if (this.showOrderProduct) {
        // BESPOKE BYD
        if (!this.product.customBooleans.STOCK_PRODUCT) {
          return true;
        }
        // END BESPOKE BYD
        if (!this.stockLimit) {
          return true;
        } else if (this.stockLimit && this.product.stock !== null) {
          return this.stockLimit && this.product.stock.stockTotal > 0;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showOutOfStockMessage () {
      // BESPOKE BYD
      if (!this.product.customBooleans.STOCK_PRODUCT) {
        return false;
      }
      // END BESPOKE BYD
      if (!this.stockLimit) {
        return false;
      } else if (this.product.stock !== null && this.showOrderProduct) {
        return this.product.stock.stockTotal <= 0;
      } else {
        return false;
      }
    },
    computedProduct () {
      if (!this.product.variantKey) {
        return this.product;
      } else {
        if (this.activeProductVariantId) {
          const index = this.product.productVariants.findIndex(product => {
            return product.id === this.activeProductVariantId;
          });
          return this.product.productVariants[index];
        } else {
          return this.product;
        }
      }
    }
  },
  mounted () {
    // BESPOKE BYD-129
    // Only check image if we use the default layout with images
    if (!this.orderLineLayout) {
      this.$refs.productImage.onload = () => {
        let ratioClass = 'square';
        const width = window.$(this.$refs['productImage']).context.naturalWidth;
        const height = window.$(this.$refs['productImage']).context.naturalHeight;
        const ratio = width / height;
        if (ratio < 1) {
          ratioClass = 'portrait';
        } else if (ratio > 1) {
          ratioClass = 'landscape';
        }
        this.imageRatioClass = ratioClass;
      }
    }
    // END BESPOKE BYD-129
  },
  methods: {
    selectedProduct (id) {
      this.activeProductVariantId = id;
    }
  }
};
</script>

<style>
</style>
