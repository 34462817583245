<template>
  <div class="uc_order_intermediate vuejs-intermediate">
    <div class="inner-element">
        <div class="title">
          <h3>Adjust order and backorder quantities</h3>
          <div v-translation="{ type: 'text', code: 'text_adjust_order_quantities' }"></div>
        </div>

        <div class="quantity-buttons">
          <a class="quantity-btn button square small decrement fa fa-minus" @click="updateQuantities('decrement')"></a>          

          <div class="order-wrapper">
              <span class="input-label" v-translation="{ type: 'label', code: 'lbl_adjust_ord_qty'}"></span>
              <input type="number" v-model="computedOrderQuantity" @change="manualOrderQuantity" :disabled="product.stock.stockTotal == 0" max="9999" class="input quantity quantity-field qtyleft">
          </div>

          <div class="backorder-wrapper">
              <span class="input-label" v-translation="{ type: 'label', code: 'lbl_adjust_backord_qty'}"></span>
              <input v-model="computedBackOrderQuantity" @change="manualBackOrderQuantity" max="9999" type="number" maxlength="4" class="input quantity quantity-field qtyright">
          </div>

          <a class="quantity-btn button square small increment fa fa-plus" @click="updateQuantities('increment')"></a>
        </div>
    
        <div class="stock">
            Stock: {{ product.stock.stockTotal }}
        </div>
    
        <div class="buttons-wrapper">
            <a class="btn-u btn-u-sea-shop btn-u-lg updatebutton btn-text" @click="adjustBackOrderQuantities()">
              <span v-translation="{ type: 'button', code: 'button_order1' }"></span>
            </a>
            <a @click="toggleIntermediate()" class="cancelbutton">
              <i class="fa fa-close"></i>
            </a>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Product } from 'src/models/product.model';
export default {
  props: {
    product: { type: Product, required: true },
    recId: {type: String, required: true },
    orderQuantity: { type: Number, required: true },
    backOrderQuantity: { type: Number, required: true }
  },
  data () {
    return {
      computedOrderQuantity: this.orderQuantity - this.backOrderQuantity,
      computedBackOrderQuantity: this.backOrderQuantity,
    };
  },
  methods: {
    adjustBackOrderQuantities() {
      var orderQuantity = this.computedOrderQuantity.toString();
      var backOrderQuantity = this.computedBackOrderQuantity.toString();
      var payload = { 
        values: [{
          order_value: orderQuantity, 
          backorder_value: backOrderQuantity
        }],
        rec_id: this.recId,
        prod_code: this.product.id
      };

      axios.post("/Bespoke/WebshopAPI/ProductListWebmethods.aspx/UpdateShoppingCartFromIntermediate", payload)
        .then(res => {
          let response = res.data.d;
          if (response.isSuccess) {
            window.updateOrderMessage(response.message);
            window.updateShoppingCart(response.productCount, response.orderTotal, response.shoppingcartHtml);
          } else {
            window.updateErrorMessage(response.message);
          }
          this.toggleIntermediate();
        });
    },
    toggleIntermediate() {
      this.$emit('toggleIntermediate');
    },
    updateQuantities(steps) {
      var totalQuantity = this.computedOrderQuantity + this.computedBackOrderQuantity;
      var orderQuantity = this.computedOrderQuantity;
      var backOrderQuantity = this.computedBackOrderQuantity;
      var stock = this.product.stock.stockTotal;
      if (steps == 'decrement') {
        totalQuantity--;
      } else {
        totalQuantity++;
      }

      if (stock <= 0) {
        this.computedOrderQuantity = 0;
        this.computedBackOrderQuantity = totalQuantity >= 1 ? totalQuantity : 1;
        return true;
      }

      if (totalQuantity > stock) {
        orderQuantity = totalQuantity < stock ? totalQuantity : stock;
        backOrderQuantity = totalQuantity - stock;
      } else if (totalQuantity <= stock) {
        orderQuantity = totalQuantity < stock ? totalQuantity : stock;
        backOrderQuantity = 0;
      }
      
      orderQuantity = orderQuantity < 1 ? 1 : orderQuantity;
      orderQuantity = orderQuantity > 999 ? 999 : orderQuantity;
      backOrderQuantity = backOrderQuantity > 999 ? 999 : backOrderQuantity;
      this.computedOrderQuantity = orderQuantity;
      this.computedBackOrderQuantity = backOrderQuantity;
    },
    manualOrderQuantity () {
      var quantity = parseInt(this.computedOrderQuantity);
      var backOrderQuantity = parseInt(this.computedBackOrderQuantity);
      var stock = this.product.stock.stockTotal;
      
      quantity = !isNaN(quantity) ? quantity : 1; 

      if (Math.sign(quantity) <= 0 || quantity === '') {
        quantity = 1;
      }
      
      if (quantity <= stock) {
        backOrderQuantity = 0;
      } else if (quantity > stock) {
        quantity = stock;
        backOrderQuantity = this.computedOrderQuantity - stock;
      }

      quantity = quantity > 999 ? 999 : quantity;
      backOrderQuantity = backOrderQuantity > 999 ? 999 : backOrderQuantity
      
      this.computedOrderQuantity = quantity;
      this.computedBackOrderQuantity = backOrderQuantity;
    },
    manualBackOrderQuantity () {
      var quantity = parseInt(this.computedOrderQuantity);
      var backOrderQuantity = parseInt(this.computedBackOrderQuantity);
      var stock = this.product.stock.stockTotal;
      
      backOrderQuantity = !isNaN(backOrderQuantity) ? backOrderQuantity : 0;

      if (Math.sign(backOrderQuantity) <= 0 || backOrderQuantity === '') {
        backOrderQuantity = 0;
      }

      if (stock <= 0 && backOrderQuantity < 1) {
        backOrderQuantity = 1;
      }
      
      backOrderQuantity = backOrderQuantity > 999 ? 999 : backOrderQuantity;
      
      var totalQuantity = quantity + backOrderQuantity;

      if (stock <= 0) {
        backOrderQuantity = totalQuantity
      } else if (quantity < stock && backOrderQuantity > 0) {
        quantity = stock;
        backOrderQuantity = totalQuantity - stock;
      }

      this.computedOrderQuantity = quantity;
      this.computedBackOrderQuantity = backOrderQuantity;

      // var quantity = parseInt(this.computedBackOrderQuantity);
      // var stock = this.product.stock.stockTotal;

      // quantity = !isNaN(quantity) ? quantity : 0; 

      // quantity = quantity > 999 ? 999 : quantity;

      // if (Math.sign(quantity) <= 0 || quantity === '') {
      //   quantity = 0;
      // }

      // if (stock <= 0 && quantity < 1) {
      //   quantity = 1;
      // }

      // quantity = quantity > 999 ? 999 : quantity;
      // this.computedBackOrderQuantity = this.computedOrderQuantity < stock ? 0 : quantity;
    }
  }
}
</script>

<style>

</style>